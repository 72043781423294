
































































import { defineComponent } from '@vue/composition-api'
import { useState } from '@u3u/vue-hooks'

export default defineComponent({
  name: 'TerminalAddress',
  props: {
    terminal: {
      type: Object,
      required: false,
    },
    ean: {
      type: Object,
      required: false,
    },
    cssClass: {
      type: String,
      required: false,
    },
  },
  setup(_props) {
    const { i18n } = useState('my', ['i18n'])

    return {
      i18n,
      apiDomain: process.env.VUE_APP_API_DOMAIN,
    }
  },
})
