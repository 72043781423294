


















































































































































































import {
  computed,
  defineComponent,
  onBeforeMount,
  ref,
  Ref,
} from '@vue/composition-api'
import { useGetters, useState } from '@u3u/vue-hooks'
import CustomLink from '@/components/g/CustomLink.vue'
import TerminalAddress from '@/components/my/terminals/TerminalAddress.vue'
import PanelAccordion from '@/components/ui/PanelAccordion.vue'
import GLoader from '@/components/g/Loader.vue'
import SimulationDetails from '@/components/my/guidance/SimulationDetails.vue'
import axios from 'axios'
import { getApiUrl } from '@/inc/app.config'
import auth from '@/composables/auth'

export default defineComponent({
  name: 'EanSimulationsList',
  components: {
    SimulationDetails,
    GLoader,
    PanelAccordion,
    TerminalAddress,
    CustomLink,
  },
  props: {
    ean: {
      type: Object,
      default: {},
    },
    simulations: {
      type: Array,
      default: [],
    },
  },
  setup(_props, ctx) {
    const { i18n } = useState('my', ['i18n'])
    const { currentLang } = useGetters(['currentLang'])
    const activePanels: Ref<number[]> = ref([])
    const simulation: Ref = ref(null)
    const loading: Ref<boolean> = ref(false)
    const { chrome } = useState(['chrome'])
    const globalI18n = computed(() => chrome.value.data)
    const confirmAction: Ref<boolean> = ref(false)
    const confirmMail: Ref<boolean> = ref(false)

    onBeforeMount(async () => {
      confirmAction.value = false
      confirmMail.value = false

      if (!_props.simulations || _props.simulations.length === 0) {
        await ctx.root.$router.push(
          `/${currentLang.value}/mobility-simulations/`
        )
      }
    })

    const togglePanel = (panel: number) => {
      confirmAction.value = false
      confirmMail.value = false

      if (activePanels.value.indexOf(panel) >= 0) {
        activePanels.value.splice(activePanels.value.indexOf(panel), 1)
        activePanels.value = [] // Pour ne garder que un panel ouvert à la fois
      } else {
        activePanels.value = [] // Pour ne garder que un panel ouvert à la fois
        activePanels.value.push(panel)
      }
    }

    const showDetails = (s: any) => {
      if (s) {
        confirmAction.value = false
        confirmMail.value = false

        simulation.value = s
      }
    }

    const downloadSimulation = (s: any) => {
      if (s.uUID) {
        loading.value = true
        scrollTo(0, 0)

        const config = {
          responseType: 'blob',
          headers: {
            Accept: 'application/pdf',
          },
        } as Record<string, any>

        axios
          .get(`${getApiUrl()}/guidance/pdf/${s.uUID}`, config)
          .then(res => {
            const url = window.URL.createObjectURL(res.request.response)
            const fileLink = document.createElement('a')
            fileLink.href = url
            fileLink.setAttribute('download', 'simulation.pdf')
            document.body.appendChild(fileLink)
            fileLink.click()
            loading.value = false
            confirmAction.value = false
            confirmMail.value = false
          })
          .catch(error => {
            loading.value = false
            confirmAction.value = false
            confirmMail.value = false
            console.error('Download simulation error', error)
          })
      }
    }

    const sendSimulation = async (s: any) => {
      if (s.uUID && auth.currentUser.value && auth.currentUser.value.email) {
        loading.value = true
        scrollTo(0, 0)

        await axios
          .get(
            `${getApiUrl()}/guidance/sendmail/${s.uUID}?email=${
              auth.email?.value
            }`
          )
          .then(() => {
            loading.value = false
            confirmAction.value = true
            confirmMail.value = true
          })
          .catch(res => {
            loading.value = false
            confirmAction.value = false
            confirmMail.value = false
            console.error('Send simulation error', res.value)
          })
      }
    }

    return {
      i18n,
      activePanels,
      simulation,
      togglePanel,
      showDetails,
      downloadSimulation,
      sendSimulation,
      apiDomain: process.env.VUE_APP_API_DOMAIN,
      loading,
      globalI18n: globalI18n.value.i18n,
      confirmAction,
      confirmMail,
      userEmail: auth.email,
    }
  },
})
