
































































































































import {
  computed,
  defineComponent,
  onBeforeMount,
  ref,
  Ref,
} from '@vue/composition-api'
import { useGetters, useState } from '@u3u/vue-hooks'
import CustomLink from '@/components/g/CustomLink.vue'
import GLoader from '@/components/g/Loader.vue'
import axios from 'axios'
import { getApiUrl } from '@/inc/app.config'
import auth from '@/composables/auth'

export default defineComponent({
  name: 'SimulationDetails',
  components: { GLoader, CustomLink },
  props: {
    simulation: {
      type: Object,
      default: {},
    },
  },
  setup(_props, ctx) {
    const { i18n } = useState('my', ['i18n'])
    const { currentLang } = useGetters(['currentLang'])
    const { chrome } = useState(['chrome'])
    const globalI18n = computed(() => chrome.value.data)
    const loading: Ref<boolean> = ref(false)
    const confirmAction: Ref<boolean> = ref(false)
    const confirmMail: Ref<boolean> = ref(false)

    onBeforeMount(async () => {
      if (!_props.simulation) {
        confirmAction.value = false
        confirmMail.value = false

        await ctx.root.$router.push(
          `/${currentLang.value}/mobility-simulations/`
        )
      }
    })
    const downloadSimulation = () => {
      if (_props.simulation.uUID) {
        loading.value = true
        scrollTo(0, 0)

        const config = {
          responseType: 'blob',
          headers: {
            Accept: 'application/pdf',
          },
        } as Record<string, any>

        axios
          .get(`${getApiUrl()}/guidance/pdf/${_props.simulation.uUID}`, config)
          .then(res => {
            const url = window.URL.createObjectURL(res.request.response)
            const fileLink = document.createElement('a')
            fileLink.href = url
            fileLink.setAttribute('download', 'simulation.pdf')
            document.body.appendChild(fileLink)
            fileLink.click()
            loading.value = false
            confirmAction.value = false
            confirmMail.value = false
          })
          .catch(error => {
            loading.value = false
            confirmAction.value = false
            confirmMail.value = false
            console.error('Download simulation error', error)
          })
      }
    }

    const sendSimulation = async () => {
      if (
        _props.simulation.uUID &&
        auth.currentUser.value &&
        auth.currentUser.value.email
      ) {
        loading.value = true
        scrollTo(0, 0)

        await axios
          .get(
            `${getApiUrl()}/guidance/sendmail/${_props.simulation.uUID}?email=${
              auth.email?.value
            }`
          )
          .then(() => {
            loading.value = false
            confirmAction.value = true
            confirmMail.value = true
          })
          .catch(res => {
            loading.value = false
            confirmMail.value = false
            console.error('Send simulation error', res.value)
          })
      }
    }

    return {
      i18n,
      apiDomain: process.env.VUE_APP_API_DOMAIN,
      globalI18n: globalI18n.value.i18n,
      loading,
      sendSimulation,
      downloadSimulation,
      confirmAction,
      confirmMail,
      userEmail: auth.email,
    }
  },
})
